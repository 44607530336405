<template>
  <v-app>
    <b-container fluid>
      <b-row class="p-0 m-0">
        <b-col md="12">
          <b-row>
            <b-col cols="12 mb-4" class="mt-4 p-0">
              <b-button variant="primary" v-b-modal.service>{{ $t('services.add_new_services_type') }}</b-button>
              <b-modal id="service" centered :title="$t('services.add_services_type')" hide-footer>
                <b-form @submit.prevent="saveServiceType()">
                  <b-form-group :label="$t('services.title')">
                    <b-input v-model="serviceTitle"/>
                  </b-form-group>
                  <div class="d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary" class="iq-waves-effect" @click="$bvModal.hide('service')">
                      {{ $t('services.cancel') }}
                    </b-button>
                    <b-button variant="primary ml-2" class="iq-waves-effect" type="submit">{{
                        $t('services.submit')
                      }}
                    </b-button>
                  </div>
                </b-form>
              </b-modal>
              <b-modal id="editservice" centered title="Edit Service Type" hide-footer>
                <b-form @submit.prevent="editServiceType(service)">
                  <b-form-group :label="$t('services.title')">
                    <b-input v-model="service.title"/>
                  </b-form-group>
                  <div class="d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary" class="iq-waves-effect" @click="$bvModal.hide('service')">
                      {{ $t('services.cancel') }}
                    </b-button>
                    <b-button variant="primary ml-2" class="iq-waves-effect" type="submit">{{
                        $t('services.submit')
                      }}
                    </b-button>
                  </div>
                </b-form>
              </b-modal>
              <b-modal id="deleteservicetype" size="sm" :title="$t('services.do_you_want_to_delete')" hide-footer>
                <b-form @submit.prevent="deleteServiceType(service)">
                  <div class="d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary" class="iq-waves-effect"
                              @click="$bvModal.hide('deleteservicetype')">{{ $t('services.cancel') }}
                    </b-button>
                    <b-button variant="primary ml-2" class="iq-waves-effect" type="submit">{{
                        $t('services.delete')
                      }}
                    </b-button>
                  </div>
                </b-form>
              </b-modal>
              <b-modal id="deleteservice" size="sm" :title="$t('services.do_you_want_to_delete_service')" hide-footer>
                <b-form @submit.prevent="deleteTheService(task)">
                  <div class="d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary" class="iq-waves-effect" @click="$bvModal.hide('deleteservice')">
                      {{ $t('services.cancel') }}
                    </b-button>
                    <b-button variant="primary ml-2" class="iq-waves-effect" type="submit">{{
                        $t('services.delete')
                      }}
                    </b-button>
                  </div>
                </b-form>
              </b-modal>
            </b-col>
          </b-row>
          <div class="row">
            <div class="row">
              <iq-card bodyClass="pro-bg-card" class="bg-transparent shadow-none col-md-3 col-sm-6"
                       :headerClass="item.color" v-for="(item, index) in services" :key="index">
                <template v-slot:headerTitle>
                  <h5 class="text-white">{{ item.title }}</h5>
                </template>
                <template v-slot:headerAction>
                  <div class="text-right">
                  <span class="btn btn-warning font-size-18" style="font-size: 10px;" v-b-modal.editservice
                        @click="editservice(item)">
                    {{ $t('customer.edit') }}
                  </span>
                    <span class="btn btn-danger ml-2 font-size-18" style="font-size: 10px;" v-b-modal.deleteservicetype
                          @click="deleteserviceType(item)">
                    {{ $t('customer.delete') }}
                  </span>
                  </div>
                </template>
                <template v-slot:body>
                  <div :list="item.list" group="scrumservice">
                    <iq-card
                      v-for="(element, index) in item.list"
                      :key="element.name +'-'+ index"
                      bodyClass="pro-task-bg-card"
                      headerClass="pro-task-bg-card"
                    >
                      <template v-slot:headerTitle>
                        <b-img v-if="element.profile_image" class="profile-pic height-100 width-100" fluid
                               :src="element.profile_image" alt="profile-pic"/>
                        <h5 class="card-title text-muted">{{ element.name }}</h5>
                        <h6 class="text-muted" v-if="element.active === 1">
                          Active
                        </h6>
                        <h6 class="text-muted" v-if="element.active === 2">
                          {{ element.fdate }} - {{ element.ldate }}
                        </h6>
                        <h6 class="text-muted" v-if="element.active === 3">
                          Inactive
                        </h6>
                        <h6 class="text-muted" v-if="element.duration">
                          Duration: {{ element.duration }} {{ element.duration_type }}
                        </h6>
                        <h6 class="text-muted" v-if="element.price">
                          Price: {{ element.price }}
                        </h6>
                        <p class="font-size-12">{{ element.description }}</p>
                        <div class="d-flex justify-content-between">
                          <div class="text-right">
                          <span class="btn btn-primary" style="font-size: 10px;" v-b-modal.task
                                @click="editTask(element,item)">
                            {{ $t('customer.edit') }}
                          </span>
                            <span class="btn btn-danger ml-2" style="font-size: 10px;" v-b-modal.deleteservice
                                  @click="deleteservice(element)">
                            {{ $t('customer.delete') }}
                          </span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </div>
                  <b-button variant=" iq-bg-primary" size="lg" block @click="addService(item)">
                    {{ $t('services.add_service') }}
                  </b-button>
                </template>
              </iq-card>
              <b-modal id="task" centered :title="$t('services.new_service')" hide-footer>
                <b-form @submit.prevent="saveService(task)" autocomplete="off">
                  <b-row>
                    <b-col cols="8">
                      <b-form-input type="text" :placeholder="$t('services.service_name')"
                                    v-model="task.name"></b-form-input>
                      <b-form-input type="number" :placeholder="$t('services.service_price')"
                                    v-model="task.price"></b-form-input>
                      <span class="valuta">{{ currency }}</span>
                      <b-row>
                        <b-col cols="6">
                          <b-form-input type="number" v-model="task.duration"
                                        :placeholder="$t('services.service_duration')"></b-form-input>
                        </b-col>
                        <b-col cols="6">
                          <b-form-select v-model="task.duration_type" size="lg" class="mb-3">
                            <b-form-select-option value="minutes" selected>{{
                                $t('services.minutes')
                              }}
                            </b-form-select-option>
                            <b-form-select-option value="hours">{{ $t('services.hours') }}</b-form-select-option>
                            <b-form-select-option value="days">{{ $t('services.days') }}</b-form-select-option>
                            <b-form-select-option value="weeks">{{ $t('services.weeks') }}</b-form-select-option>
                            <b-form-select-option value="months">{{ $t('services.months') }}</b-form-select-option>
                            <b-form-select-option value="years">{{ $t('services.years') }}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>

                      <b-form-group :label="$t('services.active')">
                        <b-form-radio-group
                          id="priority"
                          v-model="task.active"
                          :options="active"
                          name="priority"
                        />
                      </b-form-group>
                      <b-row v-if="task.active === 2">
                        <b-col cols="6">
                          <b-form-group :label="$t('services.from_date')">
                            <flat-pickr v-model="task.fdate" :config="dateConfig" class="form-control"/>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group :label="$t('services.to_date')">
                            <flat-pickr v-model="task.ldate" :config="dateConfig" class="form-control"/>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group>
                        <div class="add-img-user profile-img-edit">
                          <b-img class="profile-pic height-150 width-150" fluid :src="task.profile_image"
                                 alt="profile-pic"/>
                          <input type="hidden" v-model="task.profile_image">
                          <div class="p-image">
                            <b-button variant="none" class="upload-button iq-bg-primary position-relative">
                              <input type="file" ref="file" @change="previewImage(task)" class="h-100 position-absolute"
                                     accept="image/*" style="opacity: 0;"/>
                              {{ $t('services.file_upload') }}
                            </b-button>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-input type="text" placeholder="Service Description" v-model="task.description"/>
                    </b-col>
                    <b-col cols="12">
                      <b-form-checkbox inline v-model="task.allEmployees">{{ $t('employees_list.employees_list')}}</b-form-checkbox>
                      <v-combobox
                        v-model="task.theEmployees"
                        :items="task.employeesList"
                        item-text="name"
                        item-value="token"
                        label="Select who will use this service"
                        multiple
                        chips
                        clearable
                        v-show="task.allEmployees !== true"
                      >
                      </v-combobox>

                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <b-button variant=" iq-bg-primary" class="iq-waves-effect" @click="$bvModal.hide('task')">
                      {{ $t('services.cancel') }}
                    </b-button>
                    <b-button variant="primary ml-2" class="iq-waves-effect" type="submit">{{
                        $t('services.submit')
                      }}
                    </b-button>
                  </div>
                </b-form>
              </b-modal>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </v-app>
</template>
<script>
import { core, flatpickerSetting } from '../../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Projectservice',
  mounted () {
    core.index()
    this.getServices()
    this.getEmployees()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      dateConfig: flatpickerSetting,
      active: [
        { text: 'Unlimited', value: 1 },
        { text: 'Between', value: 2 },
        { text: 'Inactive', value: 3 }
      ],
      colors: [
        'bg-primary',
        'bg-success',
        'bg-warning',
        'bg-info',
        'bg-danger'
      ],
      service: this.defaultservice(),
      task: [{
        id: 0,
        name: '',
        description: '',
        active: 0,
        duration_type: 'minutes',
        fdate: '',
        ldate: '',
        profile_image: require('../../../assets/images/user/user-11.png'),
        theEmployees: [],
        employeesList: [],
        allEmployees: false
      }],
      services: [],
      serviceTitle: '',
      currency: 'EUR'
    }
  },
  methods: {
    updateAll (e) {
      console.log(e.target)
    },
    defaultservice () {
      return {
        id: 0,
        title: '',
        color: '',
        list: []
      }
    },
    getEmployees () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_EMPLOYEES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const employeeList = response.data.employees
            if (employeeList.length) {
              const newEmployeeList = []
              for (let x = 0; x < employeeList.length; x++) {
                newEmployeeList.push({
                  name: employeeList[x].name,
                  token: employeeList[x].token
                })
              }
              this.task.employeesList = newEmployeeList
              console.log(this.task.employeesList)
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getServices () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SERVICES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.services = response.data.services
            this.currency = response.data.currency
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    onDragg (service, item) {
    },
    saveServiceType () {
      const dataPost = {
        name: this.serviceTitle,
        id: null,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_SERVICES_TYPE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const newItem = []
            newItem.id = response.data.serviceId
            newItem.color = 'bg-primary'
            newItem.title = this.serviceTitle
            this.services.push(newItem)
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      this.$bvModal.hide('service')
    },
    editServiceType (item) {
      const dataPost = {
        name: item.title,
        id: item.id,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_SERVICES_TYPE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getServices()
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      this.$bvModal.hide('editservice')
    },
    deleteServiceType (item) {
      const dataPost = {
        id: item.id || null,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_DELETE_SERVICES_TYPE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getServices()
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      this.$bvModal.hide('deleteservicetype')
    },
    saveService (item) {
      const formData = new FormData()
      formData.append('id', item.id)
      if (this.$refs.file.files[0]) {
        formData.append('file', this.$refs.file.files[0])
      }
      formData.append('company', this.companyToken)
      formData.append('name', item.name)
      formData.append('price', item.price)
      formData.append('duration', item.duration)
      formData.append('duration_type', item.duration_type)
      formData.append('description', item.description)
      formData.append('active', item.active)
      formData.append('availability_from', item.fdate)
      formData.append('availability_to', item.ldate)
      formData.append('service_type', item.parentId)
      const theEmployees = item.allEmployees ? 'ALL' : JSON.stringify(item.theEmployees)
      formData.append('employees', theEmployees)
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_SERVICE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            this.getServices()
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      this.$bvModal.hide('task')
    },
    deleteTheService (item) {
      const dataPost = {
        id: item.id,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_DELETE_SERVICES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getServices()
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      this.$bvModal.hide('deleteservice')
    },
    addService (service) {
      this.service = service
      this.task = []
      this.task.parentId = service.id
      this.$bvModal.show('task')
    },
    editservice (item) {
      this.service = item
    },
    deleteserviceType (item) {
      this.service = item
    },
    deleteservice (item) {
      this.task = item
    },
    editTask (item, service) {
      this.service = service
      this.task = item
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.profile_image = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    }
  }
}
</script>

<style>
.valuta {
  position: absolute;
  margin-top: -35px;
  right: 20px;
  background: white;
}
</style>
